.container {
    display: flex;
    flex-direction: column;
    padding: 24px;
    width: 100%;
    column-gap: 32px;
    row-gap: 32px;
    margin-left: 292px;
}

.productContainer {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 16px;
}

@media screen and (max-width: 768px) {
    .container {
        padding: 16px;
        margin-left: 0;
        row-gap: 16px;
    }
}