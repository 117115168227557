.imgIcon {
    width: 24px;
    position: relative;
    border-radius: 24px;
    height: 24px;
    object-fit: cover;
}
.title {
    font-family: "Inter", sans-serif;
    line-height: 24px;
}
.logoTitle {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 6px;
}
.createUniqueAiGenerated {
    align-self: stretch;
    position: relative;
    font-size: 14px;
    line-height: 20px;
    color: #7a7a7a;
    display: inline-block;
    min-height: 40px;
}
.content {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;
}
.categoryText {
    position: relative;
    line-height: 12px;
    font-weight: 500;
}
.primaryCategory {
    border-radius: 14px;
    background-color: rgba(0, 0, 0, 0.24);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 4px 8px;
}
.eyeIcon {
    width: 14px;
    position: relative;
    height: 14px;
    overflow: hidden;
    flex-shrink: 0;
}
.visitButton {
    width: 24px;
    border-radius: 6px;
    background-color: rgba(0, 0, 0, 0.24);
    height: 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 4px 8px;
    box-sizing: border-box;
    cursor: pointer;
}
.bottom {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    min-height: 24px;
}
.productcard {
    width: 353px;
    min-height: 136px;
    position: relative;
    border-radius: 8px;
    background-color: var(--bg-1);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 12px;
    box-sizing: border-box;
    gap: 16px;
    text-align: left;
    font-size: 16px;
    color: rgba(255, 255, 255, 0.9);
    font-family: inherit;
    transition: background-color 160ms ease-out;
}

.productcard:hover {
    background-color: #2D2E2D;
}


@media screen and (max-width: 1024px) {
    .productcard {
        min-width: 100%;
    }
}

@media screen and (max-width: 480px) {
    .productcard {
        min-width: 90vw;
    }
}

@media screen and (min-width: 1441px){
    .productcard {
        width: 383px;
    }
}

@media screen and (min-width: 3840px){
    .productcard {
        width: 373px;
    }
}