:root {
    --primary-color: rgba(239, 74, 23, 1); /* Primary */
    --secondary-color: #F0E418; /* Secondary */
    --tertiary-color: #21A636; /* Tertiary */
    --text-color: rgba(255, 255, 255, 0.90);; /* White */
    --secondary-text-color: #7A7A7A; /* Gray */
    --tertiary-text-color: #B8B8B8; /* Light Gray */
    --bg-color: #252625; /* Black */
    --bg-m1: #1E1F1E; /* Elevation Minus 1 */
    --bg-1: #313331; /* Elevation 1 */
  }

h1{
    font-family: "Inter", sans-serif;
    font-size: 28px;
    letter-spacing: -0.02em;
    line-height: 32px;
    font-weight: 500;
    text-align: left;
    color: var(--text-color);
  }

.title {
    color: var(--text-color);
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px; /* 150% */
}

.titleSecondary {
    color: var(--secondary-text-color);
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px; /* 150% */
    letter-spacing: -0.16px;
}

.titleLarge {
    color: var(--text-color);
    font-family: Inter;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px; /* 120% */
}

.bodySmallSecondary {
    color: var(--secondary-text-color);
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
}

.bodySecondary {
    color: var(--secondary-text-color);
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: -0.32px;
}

.caption {
    color: var(--tertiary-text-color);
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
    line-height: 12px; /* 100% */
}
  
.buttonText {
    color: var(--text-color);
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px; /* 171.429% */
    letter-spacing: -0.28px;
    text-align: center;
}

button {
    cursor: pointer;
}

.primaryButton {
    width: 100%;
    border-radius: 4px;
    background-color: var(--primary-color);
    border-bottom: 4px solid rgba(22, 22, 24, 0.12);
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    height: 32px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 2px 16px;
    color: var(--text-color);
    font-family: inherit;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px; /* 171.429% */
    letter-spacing: -0.28px;
    text-align: center;
    transition: border-bottom-width 160ms ease-out;
}

.primaryButton:hover {
    border-bottom: 0px solid rgba(22, 22, 24, 0.12);
}
    
p, h1, h2, h3, h4, h5, h6{
    margin-block-start: 0;
    margin-block-end: 0;
}

a {
    text-decoration: none;
    color: inherit; /* Or any color you want */
  }