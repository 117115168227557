.imgIcon {
    width: 48px;
    position: relative;
    border-radius: 8px;
    height: 48px;
    object-fit: cover;
    background-color: var(--bg-1);
    }
    .toolinfo {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 16px;
    }
    .visitWebsite {
    position: relative;
    letter-spacing: -0.02em;
    line-height: 24px;
    font-weight: 500;
    }

    .toolhead {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    }
    
    .productdetails {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 36px;
    text-align: left;
    font-size: 20px;
    color: rgba(255, 255, 255, 0.9);
    font-family: Inter;
    }

    @media screen and (max-width: 480px) {
        .toolhead {
            flex-direction: column;
            gap: 12px;
            align-items: flex-start;
         }
        
    }