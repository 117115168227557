.mobileNav {
    display: none;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0px;
    row-gap: 16px;
}

@media screen and (max-width: 768px) {
    .mobileNav  {
        display: flex;
    }
}