@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500&display=swap');

body {
  margin: 0;
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--bg-color);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
  width: 8px; /* Set the width of the scrollbar */
}

::-webkit-scrollbar-track {
  background: #252625; /* Color of the scrollbar track */
}

::-webkit-scrollbar-thumb {
  background: #313331; /* Color of the scrollbar thumb */
  border-radius: 24px; /* Roundness of the scrollbar thumb */
}