.headerContainer {
    padding: 16px 24px 0px 24px;
    align-items: center;
    display: inline-flex;
    width: auto;
}

.headerimg{
    width: 100%;
    position: relative;
    max-width: 269px;
    height: 240px;
    object-fit: cover
}

@media screen and (max-width: 480px) {
    .headerContainer {
        flex-direction: column;
        padding: 16px;
    }
}
