.sideNav {
    min-width: 292px;
    max-width: 292px;
    position: fixed;
    background-color: var(--bg-m1);
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 24px 12px;
    box-sizing: border-box;
    row-gap: 24px;
    text-align: left;
    overflow-y: scroll;
    }

.categoriesContainer {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 4px;
    }

.listItem {
     width: 100%;
     height: 36px;
    border-radius: 6px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 8px;
    box-sizing: border-box;
    font-family: inherit;
    font-size: 16px;
    letter-spacing: -0.01em;
    line-height: 24px;
    font-weight: 500;
    color: var(--secondary-text-color);
    text-align: left;
        }



@media screen and (max-width: 768px) {
    .productcard {
        min-width: 100%;
    }
}

@media screen and (max-width: 768px) {
    .sideNav {
         display: none;
        }
}